import React from 'react'
import './Standard.css'
import './States.css'
import logo from '../../img/AZVRC2024_Full_White_Logo.png'

function VolunteerStates() {
  return (
    <div className='standard-box'>
      <div className='states-header'>
      <img src={logo} className='states-logo'></img>
      </div>
    <div className='standard-container'>
      <center>
        <h1>Volunteer at States!</h1>
        <div className='row'>
        <div className='row-item'>
        <h2>Middle School</h2>
          <p>March 2, 2024</p>
          <p>Arizona College Prep Middle School, Chandler</p>
          <a href="http://volunteer-ms.18x18az.org" target="_blank" className='link'>
          <button value="SIGN UP" className='ms-button'>Volunteer</button>
          </a>
          </div>
        <div className='row-item'>
        <h2>High School</h2>
          <p>March 8-9, 2024</p>
          <p>Desert Ridge High School, Mesa</p>
          <a href="http://volunteer-hs.18x18az.org" target="_blank" className='link'>
          <button value="SIGN UP" className='hs-button'>Volunteer</button>
          </a>
          </div>
          </div>
          </center>
    </div>
    </div>
  )
}

export default VolunteerStates
import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Teams from './components/pages/Teams';
import Partners from './components/pages/Partners';
import Volunteers from './components/pages/Volunteers';
import VolunteerStates from './components/pages/VolunteerStates';
import About from './components/pages/About';
import Donate from './components/pages/Donate';
import Footer from './components/Footer';
import NoPage from './components/pages/NoPage';

function App() {
  return (
    <div>
    <Router>
    <Navbar />
    <Routes>
      <Route exact path="/" element={<Home/>}/>
      <Route exact path="/teams" element={<Teams/>}/>
      <Route exact path="/partners" element={<Partners/>}/>
      <Route exact path="/volunteers" element={<Volunteers/>}/>
      <Route exact path="/states" element={<VolunteerStates/>}/>
      <Route exact path="/about" element={<About/>}/>
      <Route exact path="/donate" element={<Donate/>}/>
      <Route path="*" element= {<NoPage/>} />
    </Routes>
    <Footer />
    </Router>
    </div>
  );
}

export default App;

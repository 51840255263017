import React from 'react'
import AboutSection from '../AboutSection'

function About() {
  return (
    <>
    <AboutSection />
    </>
  )
}

export default About
import React from 'react'
import './Standard.css'

function Donate() {
  return (
    <div className='standard-box'>
          <div className='donate-header'>
    <p>DONATE</p>
    </div>
    <div className='standard-container'>

        <p>We thank you for your interest in our nonprofit.</p>
        <br></br>
        <p>Donations to 18x18az help us pay for equipment, trophies, banners, server time, and other expenses associated with running and producing quality robotics events.
        </p>
        <p>If you are interested in donating or supporting us via other means, please email us at <a href='mailto:contact@18x18az.org'>contact@18x18az.org</a>.</p>
    </div>
    </div>
  )
}

export default Donate
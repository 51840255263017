import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button.js';
import logo from '../logo.svg';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960)
            setButton(false);
        else
            setButton(true);
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
    <>
    <nav className='navbar'>
        <div className='navbar-container'>
            <Link to="/" className='navbar-logo' onClick={closeMobileMenu}>
            <img src={logo} className='logo'/>
            </Link>
            <div className='menu-icon' onClick={handleClick}>
                < i className={click ? 'fa-solid fa-times' : 'fa-solid fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                    <Link to='/states' className='nav-links' onClick={closeMobileMenu}>States</Link>
                </li>
                <li className='nav-item'>
                    <Link to='/teams' className='nav-links' onClick={closeMobileMenu}>Teams</Link>
                </li>
                <li className='nav-item'>
                    <Link to='/partners' className='nav-links' onClick={closeMobileMenu}>Event Partners</Link>
                </li>
                <li className='nav-item'>
                    <Link to='/volunteers' className='nav-links' onClick={closeMobileMenu}>Volunteers</Link>
                </li>
                <li className='nav-item'>
                    <Link to='/about' className='nav-links' onClick={closeMobileMenu}>About Us</Link>
                </li>
            </ul>
        </div>
    </nav>
    </>
  )
}

export default Navbar
import React from 'react'
import './Standard.css'

function Volunteers() {
  return (
    <div className='standard-box'>
      <div className='volunteers-header'>
      <p>VOLUNTEERS</p>
      </div>
    <div className='standard-container'>
      <h1>Volunteer with 18x18az!</h1>
      <p>
        None of our events are possible without the help of our volunteers.
      </p>
      <br></br>
      <p>
        If you are interested in volunteering with us, please fill out the interest form below to join our network.
      </p>
      <div className='iframe-wrapper'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfXs1Zysa8V7Qumpz9Gk15O-_wg9toTMbudNtLCI_J8zCJ7pg/viewform?embedded=true" width="640" height="1429" frameborder="0" margin="auto" title="form">Loading…</iframe>
      </div>
    </div>
    </div>
  )
}

export default Volunteers
import React from 'react'
import './Standard.css'

function Partners() {
  return (
    <div className='standard-box'>

      <div className='partners-header'>
      <p>EVENT PARTNERS</p>
      </div>
    <div className='standard-container'>
      <p>Firstly, thank you for supporting the Arizona VEX community by hosting an event!</p>
      <br></br>
      <p>
        If you would like 18x18az to support your event please fill out the form below.
        If you have any questions, please email us at <a href='mailto:contact@18x18az.org'>contact@18x18az.org</a>
      </p>
      <div className='iframe-wrapper'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSclTO1qgdy45kAAlP3Fmtb9TxpV-gwXMK6VIstPusNmm9qSlg/viewform" width="640" height="1429" frameborder="0" margin="auto" title="form">Loading…</iframe>
      </div>
    </div>
    </div>
  )
}

export default Partners
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import wolfboi from '../wolfboi.svg';

function Footer() {
  return (
    <div className='footer-container'>

      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link className='qlink' to='/about'>About</Link>
            <Link className='qlink' to='/about'>Disclosures</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <a href="mailto:contact@18x18az.org">contact@18x18az.org</a>
            <Link className='qlink' to='/donate'>Donate</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Quick Links</h2>
            <a className='qlink' href='https://yt.18x18az.org'>YouTube</a>
            <a className='qlink' href='https://github.com/18x18az'>GitHub</a>
            <a className='qlink' href='https://discord.gg/zdXB74qS5B'>AZ Robotics Discord</a>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>

          </div>
          <small class='website-rights'>18x18az © 2024</small>
          <div class='social-icons'>


          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;

import React from 'react';
import '../App.css';
import './pages/Standard.css';

import Articles from '../docs/disclosures/ArticlesOfIncorporation.pdf'
import Exemption from '../docs/disclosures/1023-EZ.pdf'
import Letter from '../docs/disclosures/LetterOfDetermination.pdf'
import Tax2020 from '../docs/disclosures/990-N-2020.pdf'
import Tax2021 from '../docs/disclosures/990-N-2021.pdf'

function AboutSection() {
  return (
    <div className='standard-box'>
          <div className='about-header'>
    <p>ABOUT US</p>
    </div>
    <div className='standard-container'>
      <p>
      We are a 501(c)(3) nonprofit organization dedicated to promoting STEM education,
      specializing in hosting and supporting high quality VEX Robotics Competition events in Arizona.
      Since our founding in 2020, we have supported local events at all levels, from qualifiers to state championships.
      </p>
        <h2>501(c)(3) Nonprofit Disclosures</h2>
        <a href={Articles} target="_blank" rel="noreferrer">Articles Of Incorporation</a>
        <br></br>
        <a href={Exemption} target="_blank" rel="noreferrer">Exemption Application Form 1023-EZ</a>
        <br></br>
        <a href={Letter} target="_blank" rel="noreferrer">Letter of Determination</a>
        
        <h3>Annual Electronic Notice Form 990-N:</h3>
        <a href={Tax2020} target="_blank" rel="noreferrer">Tax Year 2020</a>
        <br></br>
        <a href={Tax2021} target="_blank" rel="noreferrer">Tax Year 2021</a>
    </div>
    </div>
    
  );
}

export default AboutSection;

function NoPage() {
    return (
        <div className="nopage-box">
            <div className="nopage-header">
                <p>404</p>
                <p>¯\_(ツ)_/¯</p>
            </div>
        </div>
    );
}

export default NoPage